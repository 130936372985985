import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Tooltip,
  CircularProgress,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  Box,
  Typography,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { languages } from "../../constants/languages/languages";
import { API_URL, API_PORT } from "../../constants/appConfig";
import { useEffect, useState } from "react";
import { useLanguage } from "../../components/languageProvider";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Trash from "@mui/icons-material/DeleteOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";

const PackageName = ({ item, currentLanguage }) => {
  const packageName = {
    tr: item.packageNameTr,
    de: item.packageNameDe,
    fr: item.packageNameFr,
    it: item.packageNameIt,
    nl: item.packageNameNl,
    en: item.packageNameEn,
  };

  return (
    <span className="font-bold">
      {packageName[currentLanguage] || packageName.en}
    </span>
  );
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [packages, setPackages] = useState([]);
  const [size, setSize] = useState(10);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deletePackageModal, setDeletePackageModal] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [showImagesModal, setShowImagesModal] = useState(false);
  const [activeTab, setActiveTab] = useState("1");

  const getAllPackages = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBg?page=${page}&size=${size}`
      );
      const data = await response.json();
      setPackages(data?.content);
      setTotalPages(data?.totalPages);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getAllPackages();
  }, []);

  const TABLE_HEAD = [
    texts.packagesTableTitle.packageName,
    texts.packagesTableTitle.packageDescription,
    texts.packagesTableTitle.packageRegion,
    texts.packagesTableTitle.package3Monthly,
    texts.packagesTableTitle.package6Monthly,
    texts.packagesTableTitle.packageYearly,
    "",
    "",
  ];

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleOpenDeletePackageModal = (item) => {
    setSelectedPackage(item);
    setDeletePackageModal(true);
  };

  const handleShowImages = (item) => {
    setSelectedPackage(item);
    setShowImagesModal(true);
  };

  const handleDeletePackage = async () => {
    setDeleteLoading(true);
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/deletePackage?packageId=${selectedPackage.packageId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        getAllPackages();
        setDeletePackageModal(false);
      }
    } catch (error) {
      console.log(error);
    }
    setDeleteLoading(false);
  };

  return (
    <>
      <Card className="w-full h-full shadow-2xl">
        <Typography variant="h5" className="px-4 py-4">
          {texts.packages}
        </Typography>
        <div className="px-4">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/packages/add-package");
            }}
            startIcon={<AddCircleOutlineIcon />}
          >
            {texts.addPackage}
          </Button>
        </div>
        <CardContent>
          {loading ? (
            <div className="w-full min-w-max flex items-center justify-center">
              <CircularProgress className="h-40 w-40" />
            </div>
          ) : (
            <TableContainer>
              <Table className="w-full min-w-max" stickyHeader>
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((head) => (
                      <TableCell
                        key={head}
                        className="justify-center border-blue-gray-100 bg-blue-gray-50/50"
                      >
                        <span className="font-normal leading-none opacity-70">
                          {head}
                        </span>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {packages.map((item, index) => {
                    const isLast = index === packages.length - 1;
                    const classes = isLast
                      ? "py-4 font-bold"
                      : "py-4 border-b border-blue-gray-50 font-bold";

                    return (
                      <TableRow key={item.id}>
                        <TableCell className={classes}>
                          <PackageName
                            item={item}
                            currentLanguage={currentLanguage}
                          />
                        </TableCell>
                        <TableCell className={classes}>
                          <span>{item.packageDescription}</span>
                        </TableCell>
                        <TableCell className={classes}>
                          <span>{item.packageRegion}</span>
                        </TableCell>
                        <TableCell className={classes}>
                          <span>
                            {item.package3Montly} {texts.pricePerMonth}
                          </span>
                        </TableCell>
                        <TableCell className={classes}>
                          <span>
                            {item.package6Montly} {texts.pricePerMonth}
                          </span>
                        </TableCell>
                        <TableCell className={classes}>
                          <span>
                            {item.package12Montly} {texts.pricePerMonth}
                          </span>
                        </TableCell>
                        <TableCell className={classes}>
                          <Tooltip
                            title={texts.packagesTableTitle.packageDetails}
                          >
                            <IconButton onClick={() => handleShowImages(item)}>
                              <VisibilityIcon className="text-blue-500" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={texts.packagesTableTitle.editPackage}>
                            <IconButton
                              onClick={() => {
                                navigate(
                                  `/packages/edit-package/${item.packageId}`
                                );
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={texts.deletePackage}>
                            <IconButton
                              onClick={() => handleOpenDeletePackageModal(item)}
                            >
                              <Trash className="text-red-500" />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
          />
        </CardActions>

        <Dialog
          open={deletePackageModal}
          onClose={() => setDeletePackageModal(false)}
        >
          <DialogTitle>{texts.deletePackageTitle}</DialogTitle>
          <DialogContent>
            <Typography>{texts.packageDeleteInfo}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => setDeletePackageModal(false)}
            >
              {texts.no}
            </Button>
            <Button
              loading={deleteLoading}
              color="secondary"
              onClick={handleDeletePackage}
            >
              {texts.yes}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={showImagesModal}
          onClose={() => setShowImagesModal(false)}
          fullWidth
          maxWidth="lg"
        >
          <DialogTitle>{texts.packagesTableTitle.packageDetails}</DialogTitle>
          <DialogContent>
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
            >
              <Tab
                label={texts.packagesTableTitle.packageInformation}
                value="1"
              />

              <Tab label={texts.Images} value="2" />
            </Tabs>
            <Box hidden={activeTab !== "1"}>
              <Typography variant="h6" className="font-bold mb-4">
                <PackageName
                  item={selectedPackage}
                  currentLanguage={currentLanguage}
                />
              </Typography>
              <div className="space-y-2">
                <Typography>
                  <strong>
                    {texts.packagesTableTitle.packageDescription}:{" "}
                  </strong>
                  {selectedPackage?.packageDescription}
                </Typography>
                <Typography>
                  <strong>{texts.packagesTableTitle.packageRegion}: </strong>
                  {selectedPackage?.packageRegion}
                </Typography>
                <Typography>
                  <strong>{texts.packagesTableTitle.package3Monthly}: </strong>
                  {selectedPackage?.package3Montly}
                </Typography>
                <Typography>
                  <strong>{texts.packagesTableTitle.package6Monthly}: </strong>
                  {selectedPackage?.package6Montly}
                </Typography>
                <Typography>
                  <strong>{texts.packagesTableTitle.packageYearly}: </strong>
                  {selectedPackage?.package12Montly}
                </Typography>
              </div>
            </Box>
            <Box hidden={activeTab !== "2"}>
              <Typography>{texts.Images}</Typography>
              <div className="flex flex-wrap justify-center gap-4 w-full">
                {selectedPackage?.packageImages?.map((image, index) => (
                  <img
                    key={index}
                    src={API_URL + ":" + API_PORT + "/" + image.imageName}
                    alt={image.imageName}
                    className="w-1/6"
                  />
                ))}
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowImagesModal(false)} color="primary">
              {texts.close}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </>
  );
};

export default Index;
