import {
  Card,
  Button,
  CardContent,
  TextField,
  CircularProgress,
  Typography,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import React, { useEffect, useState } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { countryOptions } from "../../../constants/countries";

const customStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: "white", // Arka plan rengi
    zIndex: 9999, // Menünün diğer öğelerin üzerinde görünmesini sağlar
  }),
};

const Index = () => {
  const { currentLanguage } = useLanguage();
  const texts = languages[currentLanguage].dealerTexts;
  const [loading, setLoading] = useState(false);
  const [dealer, setDealer] = useState({
    dealerName: "",
    dealerPhone: "",
    dealerAddress: "",
    dealerLocations: [{ dealerPostalCode: "", dealerCountry: "" }],
  });
  const { id } = useParams();
  const navigate = useNavigate();

  const addLocation = () => {
    setDealer({
      ...dealer,
      dealerLocations: [
        ...dealer.dealerLocations,
        { dealerPostalCode: "", dealerCountry: "" },
      ],
    });
  };

  useEffect(() => {
    const fetchDealer = async () => {
      if (id) {
        try {
          setLoading(true);
          const response = await fetch(
            `${API_URL}:${API_PORT}/dealer/findByIdDto?dealerId=${id}`
          );
          const data = await response.json();
          setDealer(data);
        } catch (error) {
          myToast({ status: "error", message: texts.fetchUserError });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDealer();
  }, [id, texts.fetchUserError]);

  const saveDealer = async () => {
    try {
      const response = await fetch(`${API_URL}:${API_PORT}/dealer/saveDealer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dealer),
      });
      const data = await response.json();
      if (data) {
        myToast({ status: "success", message: texts.dealerSaveSuccess });
        navigate("/dealer");
      } else {
        myToast({ status: "error", message: texts.dealerSaveError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.dealerSaveError });
    }
  };

  const updateDealer = async () => {
    try {
      const response = await fetch(
        `${API_URL}:${API_PORT}/dealer/updateDealer`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dealer),
        }
      );
      const data = await response.json();
      if (data) {
        myToast({ status: "success", message: texts.dealerUpdateSuccess });
        navigate("/dealer");
      } else {
        myToast({ status: "error", message: texts.dealerUpdateError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.dealerUpdateError });
    }
  };

  const handleSave = async () => {
    dealer.dealerId ? updateDealer() : saveDealer();
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Card className="w-full h-full shadow-2xl p-4">
          <Typography variant="h5">{texts.addDealer}</Typography>
          <CardContent className="flex flex-col gap-2">
            <TextField
              value={dealer.dealerName}
              onChange={(e) =>
                setDealer({ ...dealer, dealerName: e.target.value })
              }
              label={texts.dealerTableTitle.dealerName}
              size="small" // Küçük boyut
              variant="outlined" // Varyant
            />
            <TextField
              value={dealer.dealerPhone}
              onChange={(e) =>
                setDealer({ ...dealer, dealerPhone: e.target.value })
              }
              label={texts.dealerTableTitle.dealerPhone}
              size="small" // Küçük boyut
              variant="outlined" // Varyant
            />
            <TextField
              value={dealer.dealerAddress}
              onChange={(e) =>
                setDealer({ ...dealer, dealerAddress: e.target.value })
              }
              label={texts.dealerTableTitle.dealerAddress}
              size="small" // Küçük boyut
              variant="outlined" // Varyant
            />
            <div>
              {dealer?.dealerLocations?.map((location, index) => (
                <div key={index} className="mb-4 flex flex-col gap-2">
                  <TextField
                    value={location.dealerPostalCode}
                    maxLength={5}
                    onChange={(e) => {
                      const updatedLocations = [...dealer.dealerLocations];
                      updatedLocations[index] = {
                        ...location,
                        dealerPostalCode: e.target.value,
                      };
                      setDealer({
                        ...dealer,
                        dealerLocations: updatedLocations,
                      });
                    }}
                    label={`${texts.dealerPostalCode} ${index + 1}`}
                    size="small" // Küçük boyut
                    variant="outlined" // Varyant
                  />

                  <Select
                    options={countryOptions}
                    placeholder={texts.dealerCountry}
                    styles={customStyles}
                    value={countryOptions.find(
                      (option) => option.value === location.dealerCountry
                    )}
                    onChange={(e) => {
                      const updatedLocations = [...dealer.dealerLocations];
                      updatedLocations[index] = {
                        ...location,
                        dealerCountry: e.value,
                      };
                      setDealer({
                        ...dealer,
                        dealerLocations: updatedLocations,
                      });
                    }}
                    isSearchable
                    className="font-[Inter] w-full bg-white"
                  />
                </div>
              ))}

              <Button
                onClick={addLocation}
                variant="contained"
                color="primary"
                className="mt-4"
              >
                {texts.addZipCode}
              </Button>
            </div>

            <Button
              color="primary"
              variant="contained"
              className="w-full font-[Inter]"
              onClick={handleSave}
            >
              {dealer.dealerId ? texts.updateDealer : texts.addDealer}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Index;