import {
  Card,
  CardContent,
  Button,
  TextField,
  CircularProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { languages } from "../../../constants/languages/languages";
import React, { useState, useEffect, useRef } from "react";
import { API_URL, API_PORT } from "../../../constants/appConfig";
import myToast from "../../../components/myToast";
import { useLanguage } from "../../../components/languageProvider";
import { useNavigate, useParams } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const Index = () => {
  const { currentLanguage } = useLanguage();
  const { id } = useParams();
  const texts = languages[currentLanguage];
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);

  const [packageItem, setPackageItem] = useState({
    packageNameTr: "",
    packageNameDe: "",
    packageNameFr: "",
    packageNameIt: "",
    packageNameNl: "",
    packageNameEn: "",
    packageDescription: "",
    packageMontly: "",
    package3Montly: "",
    package6Montly: "",
    package12Montly: "",
    package24Montly: "",
    packageSerial: "",
    packageRegion: currentLanguage,
    packageType: "0",
    packageImages: [],
  });

  useEffect(() => {
    if (id) {
      fetchPackage();
    }
  }, [images]);

  const fetchPackage = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_URL}:${API_PORT}/package/getAllPackageWithBgByPackageId?packageId=${id}`
      );
      const data = await response.json();
      if (response.status === 200) {
        setPackageItem(data);
      } else {
        myToast({ status: "error", message: texts.packageFetchError });
      }
    } catch (error) {
      myToast({ status: "error", message: texts.packageFetchError });
    } finally {
      setLoading(false);
    }
  };

  const savePackage = async () => {
    try {
      setLoading(true);
      const uploadImagesResponse = await uploadImages();

      if (uploadImagesResponse.length > 0) {
        const payload = {
          ...packageItem,
          packageBgIds: uploadImagesResponse.join(","),
        };

        const response = await fetch(
          `${API_URL}:${API_PORT}/package/savePackage`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
        if (response.status === 200) {
          myToast({ status: "success", message: texts.packageSaveSuccess });
          navigate("/packages");
        } else {
          myToast({ status: "error", message: texts.packageSaveError });
        }
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const fileInputRef = useRef(null);

  const triggerFileInput = () => {
    fileInputRef.current.click(); // Eğer referans null değilse tıklamayı tetikle
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    const allowedExtensions = ["jpeg", "jpg", "png"];
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileNameParts = file.name.split(".");
      const fileExtension =
        fileNameParts[fileNameParts.length - 1].toLowerCase();

      if (!allowedExtensions.includes(fileExtension)) {
        myToast({ status: "error", message: texts.fileExtensionError });
        continue;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const imageData = reader.result;
        newImages.push({ file, imageData });
        if (newImages.length === files.length) {
          setImages((prevImages) => [...prevImages, ...newImages]);
        }
      };
    }
  };

  const handleInputChange = (e, key) => {
    let value = e.target.value;

    value = value.replace(",", ".");

    if (!isNaN(value)) {
      setPackageItem({
        ...packageItem,
        [key]: value,
      });
    }
  };

  const uploadImages = async () => {
    try {
      const newImageIds = [];

      for (const image of images) {
        const formData = new FormData();
        formData.append("file", image.file);
        const imageType = "backgroundimages";
        const response = await fetch(
          `${API_URL}:${API_PORT}/image/uploadImage?imageType=${imageType}`,
          {
            method: "POST",
            body: formData,
          }
        );
        const data = await response.json();
        if (response.status === 200) {
          newImageIds.push({
            imageId: data.imageId,
          });

          myToast({ status: "success", message: texts.imageUploadSuccess });
        } else {
          myToast({ status: "error", message: texts.imageUploadError });
        }
      }
      setPackageItem({
        ...packageItem,
        packageImages: [...packageItem.packageImages, ...newImageIds],
      });
      setImages([]);
      const allImages = [
        ...packageItem.packageImages.map((image) => image.imageId),
        ...newImageIds.map((image) => image.imageId),
      ];

      return allImages;
    } catch (error) {
      myToast({ status: "error", message: texts.imageUploadError });
      console.error(error);
      return [];
    }
  };

  const handleSave = async () => {
    if (packageItem.packageId) {
      await savePackage();
    }
  };

  return (
    <>
      {loading ? (
        <div className="w-full min-w-max flex items-center justify-center">
          <CircularProgress />
        </div>
      ) : (
        <Card className="w-full h-full p-4">
          <Typography variant="h6">{texts.addDealer}</Typography>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameDe}
              value={packageItem.packageNameDe}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameDe: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameEn}
              value={packageItem.packageNameEn}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameEn: e.target.value,
                  packageSerial: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameTr}
              value={packageItem.packageNameTr}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameTr: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameFr}
              value={packageItem.packageNameFr}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameFr: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameIt}
              value={packageItem.packageNameIt}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameIt: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageNameNl}
              value={packageItem.packageNameNl}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageNameNl: e.target.value,
                });
              }}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.packageDescription}
              value={packageItem.packageDescription}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageDescription: e.target.value,
                });
              }}
              fullWidth
            />

            <TextField
              size="small"
              label={texts.packagesTableTitle.package3Montly}
              value={packageItem.package3Montly}
              onChange={(e) => handleInputChange(e, "package3Montly")}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.package6Montly}
              value={packageItem.package6Montly}
              onChange={(e) => handleInputChange(e, "package6Montly")}
              fullWidth
            />
            <TextField
              size="small"
              label={texts.packagesTableTitle.package12Montly}
              value={packageItem.package12Montly}
              onChange={(e) => handleInputChange(e, "package12Montly")}
              fullWidth
            />

            <TextField
              size="small"
              label={texts.packagesTableTitle.packageSerial}
              value={packageItem.packageSerial}
              onChange={(e) => {
                setPackageItem({
                  ...packageItem,
                  packageSerial: e.target.value,
                });
              }}
              fullWidth
            />
            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageType: e.target.value,
                })
              }
            >
              <option value="0">{texts.packageType0}</option>
              <option value="1">{texts.packageType1}</option>
              <option value="2">{texts.packageType2}</option>
            </select>
            <select
              className="w-full h-10 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-transparent"
              onChange={(e) =>
                setPackageItem({
                  ...packageItem,
                  packageRegion: e.target.value,
                })
              }
            >
              {Object.keys(languages).map((langKey) => (
                <option key={langKey} value={langKey}>
                  {langKey}
                </option>
              ))}
            </select>
            <div className="flex justify-between mt-4">
              <Button variant="contained" onClick={triggerFileInput}>
                {texts.addImage}
              </Button>
            </div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleFileUpload}
              multiple
            />
            <div className="flex flex-col w-full gap-4">
              <span className="text-sm text-gray-400">{texts.Images}</span>
              <div className="flex flex-row w-full gap-4">
                {Array.isArray(images) &&
                  packageItem?.packageImages?.map((image, index) => (
                    <div
                      key={index}
                      className="relative group flex justify-center items-center"
                    >
                      <img
                        className="h-[105px] w-[75px] object-cover rounded-md"
                        src={API_URL + ":" + API_PORT + "/" + image.imageName}
                        alt={`Image_${index + 1}`}
                      />
                      <button
                        onClick={() => {
                          const images = packageItem.packageImages.filter(
                            (img, i) => i !== index
                          );
                          setPackageItem({
                            ...packageItem,
                            packageImages: images,
                          });
                        }}
                        className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                      >
                        <DeleteForeverIcon className="h-5 w-5 text-red-300 hover:text-red-700" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <div className="flex flex-col w-full gap-4">
              <span className="text-sm text-gray-400">
                {texts.NewlyAddedImages}
              </span>
              <div className="flex flex-row w-full gap-4">
                {Array.isArray(images) &&
                  images.map((image, index) => (
                    <div
                      key={index}
                      className="relative group flex justify-center items-center"
                    >
                      <img
                        className="h-[105px] w-[75px] object-cover rounded-md"
                        src={image.imageData}
                        alt={`Image_${index + 1}`}
                      />
                      <button
                        onClick={() => {
                          const newImages = images.filter(
                            (img, i) => i !== index
                          );
                          setImages(newImages);
                        }}
                        className="absolute top-2 right-2 z-10 p-1 bg-white rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                      >
                        <DeleteForeverIcon className="h-5 w-5 text-red-300 hover:text-red-700" />
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <Button
              variant="contained"
              onClick={handleSave}
              fullWidth
              sx={{ marginTop: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : texts.updatePackage}
            </Button>
          </CardContent>
        </Card>
      )}
    </>
  );
};

export default Index;
